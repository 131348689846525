import React from 'react';
import { Image, ResponsiveEmbed } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import pic from './assets/profile-square.jpg';

export const Home = () => (
    <div className="profile">
        <ResponsiveEmbed style={{ width: 200, height: 'auto' }}><Image src={pic} roundedCircle /></ResponsiveEmbed>
        <p>Originally from the USA, I've been living and working in Wellington for over 8 years. I decided to ✨follow my dreams✨ and study software development at Wellington ICT. I'm still studying, and although everything (including this website) is a work in progress, I'm excited to keep exploring the things I've learned so far.</p>
        <h3>Things I enjoy:</h3>
        <p>Back-end development. Cats (duh). Cross-stitching. Historical footwear. Video games (gotta catch all those Pokemon...games). Android. Linux. A trim, flat white, a journal, and a fancy pen.</p>
        <Link to ='/projects'><Button variant='secondary'>Check out my projects!</Button></Link>
    </div>
)