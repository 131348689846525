import React from 'react'
import tools from "./assets/tools.png";

var wd = window.innerWidth;
if (wd < 200) {
      wd = 100;
} else if (wd < 550) {
    wd = wd - 100;
} else {
    wd = tools.width;
}

export const NoMatch = () => (
    <div className="broken">
        <h2 className="page-title">Oops this page is under construction! Come back soon!</h2>
        <img width={wd} src={tools}></img>
    </div>
)