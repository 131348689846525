import React from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CardDeck from 'react-bootstrap/CardDeck';
import { Link } from 'react-router-dom';
import conway from './assets/conway-thumb.jpg';
import stitch from './assets/stitch-thumb.jpg';

export const Projects = () => (
    <div>
        <h2 className="page-title">Projects</h2>
        <CardDeck>
            <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src={conway} />
                <Card.Body>
                    <Card.Title>Conway's Game of Life</Card.Title>
                    <Card.Text>
                        A simple version of Conway's Game of Life written in Java using JavaFX. 
                    </Card.Text>
                    <Link to="/conway"><Button variant="secondary">Check it out!</Button></Link>
                </Card.Body>
            </Card>
            <Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src={stitch} />
                <Card.Body>
                    <Card.Title>Stichstock</Card.Title>
                    <Card.Text>
                        An Android app built in 3 days to help manage manage a pile of embroidery thread.
                    </Card.Text>
                    <Link to="/android"><Button variant="secondary">Check it out!</Button></Link>
                </Card.Body>
            </Card>
            {/*<Card style={{ width: '18rem' }}>
                <Card.Img variant="top" src="holder.js/100px180" />
                <Card.Body>
                    <Card.Title>What's next?</Card.Title>
                    <Card.Text>
                        Some thoughts on what I would like to tackle next.
                    </Card.Text>
                    <Button variant="primary"><Link to="/next" style={{ color: '#FFFFFF' }}>Check it out!</Link></Button>
                </Card.Body>
            </Card>*/}
        </CardDeck>
    </div>
)