import React from 'react';
import { ResponsiveEmbed, Row, Col, Button } from 'react-bootstrap';
import video from "./assets/conway-example.mp4";

const Video = () => {
  return (
    <div className="vid-container" max-width="550px">
      <ResponsiveEmbed>
        <video autoPlay playsInline loop muted src={video} fluid />
      </ResponsiveEmbed>
    </div>
  );
};

const Description = () => {
  return (
    <div>
      <p>So, we had a unit in Uni where we had to build <a href="https://en.wikipedia.org/wiki/Conway%27s_Game_of_Life">Conway's Game of Life</a>. However, our professors gave us this task <em>before</em> we tackled "the Big O" problem. Every group's programs had massive memory problems or tried to fit an "infinite" grid of squares into 2D arrays (in <em>Java</em>).</p>
      <p>After the unit on memory handling, I decided to tackle the task on my own. I built my Game of Life using Java with a JavaFX front-end. These were the tools we had to build it in the first place, so I stuck to it.</p>
      <p>For the record, I was not aiming for artistic beauty, but rather, something that <em>worked</em>. I journaled my progress on GitHub and go deep in depth on the whats and hows of my program. It's a humble Conway's Game of Life, but I was fairly happy I was able to get there on my own. I hope to come back to it someday soon to finish it.</p>
      <a href='https://github.com/MagneticRegulus/ConwaysGame'><Button variant='secondary'>Read more on GitHub</Button></a>
    </div>
  )
}

export const Conway = () => (
  <div>
    <h2 className="page-title">Conway's Game of Life</h2>
    <Row className="justify-content-xs-center">
      <Col xs={12} md={6}><Video /></Col>
      <Col xs={12} md={6}><Description /></Col>
    </Row>
  </div>
);