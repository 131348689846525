import React from 'react';
import video from "./assets/conway-example.mp4";

const Video = () => {
    
    var wd = window.innerWidth;
    if (wd < 600 && wd >= 200) {
      wd = wd - 100;
    } else if (wd < 200) {
      wd = 100;
    } else {
      wd = video.width;
    }
    
    return (
      <div className="vid-container">
        <video autoPlay playsInline loop muted width={wd} src={video} />
      </div>
    );
  };

export const Next = () => (
    <div>
        <h2 className="page-title">Stichstock - an Android App</h2>
        <Video />
    </div>
)