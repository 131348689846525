import React from 'react';
import video from "./assets/stitchstock.mp4";
import { ResponsiveEmbed, Row, Col, Button } from 'react-bootstrap';

const Video = () => {
  return (
    <div className="vid-container" max-width="800px">
      <ResponsiveEmbed>
        <video autoPlay playsInline loop muted src={video} fluid />
      </ResponsiveEmbed>
    </div>
  );
};

const Description = () => {
  return (
    <div>
      <p>I do a lot of cross-stitching. I'm not particularly artistic; cross-stitching is essentially pixel art with thread. But it's a nice way to relax. There's something about tactile art that really makes it worthwhile.</p>
      <p>However, something I never thought I'd have to deal with is the <em>sheer amount of embroidery floss</em> you assemble after only a few projects. Do you know how many shades of green there are??? (Answer: many.)</p>
      <p>The embroidery floss company that has the widest distribution in the world is DMC, and they have over 500 different colours in their standard collection. I already have hundreds of colours!</p>
      <p>For a long while, I used an excel spreadsheet to keep track of the colours I already had. However, that's not very efficient or user-friendly. I downloaded a few apps to try to help organise myself, but they either were overly complex ("measure the amount of thread you're using to the <em>millimetre</em>") or had poor UX. Either way, they didn't get me what I needed.</p>
      <p>In January, our professors gave us a two and a half day challenge: impress us. So I chose to build an Android app to help me keep track of my growing collection of floss / thread.</p>
      <p>The app, Stitchstock, allows users to add DMC colours to their stock, mark a skein as "low stock" (meaning, I think I'm running out of this), or mark a colour as needed regardless of whether they have it. It gives users a little shopping list of colours they need to remember to buy, which is especially important if you're like me and put projects down for...8 months...</p>
      <p>Stitchstock is far from finished. It has a few UX problems, is missing a big feature (grouping colours by project), and really needs to be tested with other potential users. I intend to keep working on it, and maybe, one day, it'll go up on Google Play.</p>
      <a href='https://github.com/MagneticRegulus/ThreadManagementApp'><Button variant='secondary'>Read more on GitHub</Button></a>
    </div>
  )
}

export const Android = () => (
  <div>
    <h2 className="page-title">Stichstock - an Android App</h2>
    <Row className="justify-content-xs-center">
      <Col xs={12} md={6}><Video /></Col>
      <Col xs={12} md={6}><Description /></Col>
    </Row>
  </div>
)